html,
body {
    /*in next the target div is __next not root*/
    min-height: 100%;
    min-width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.bold {
    font-weight: 700;
}

.links {
    cursor: pointer;
}

.links:link {
    color: inherit;
    background-color: inherit;
    text-decoration: none;
}

.links:visited {
    color: inherit;
    background-color: inherit;
    text-decoration: none;
}

.links:hover {
    color: inherit;
    background-color: inherit;
    text-decoration: none;
}

.links:active {
    color: inherit;
    font-style: none;
    background-color: inherit;
}

.underline {
    text-decoration: underline !important;
}

.success {
    color: #44d786;
}
.error {
    color: #ff1744;
}
